import { NpsEnum } from "../../enum/nps.enum";

export const props = () => ({
  zendeskId: "cab743de-0681-4851-9630-98da2a6e3d80",
  apiUrl: "https://api-petrovina-homolog.graodireto.com.br",
  logo: "graodireto",
  appTitle: "Barter Fácil",
  theme: "graodireto",
  companyLanguage: "default",
  keyCloakConfig: {
    url: "https://auth-homolog.graodireto.com.br",
    realm: "petrovina",
    clientId: "petrovina-front",
  },
  production: false,
  firebaseConfig: {
    apiKey: "",
    authDomain: "",
    projectId: "",
    storageBucket: "",
    messagingSenderId: "",
    appId: "",
    measurementId: "",
  },
  hotjarConfig: "",
  nps: {
    api: "https://api-homolog.graodireto.com.br/api",
    url: "https://graodireto-site-homolog.graodireto.com.br/nps",
    key: "4b51b41f-bf44-44db-877f-90f90ed9b7b8",
  },
  npsSourceId: NpsEnum.EASY_BARTER_HINOVE,
});
